import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './Callback.css';

export interface Props {
}

const Callback: React.FC<Props> =
  (props, context) => {
    return (
      <div className="Callback">
        <FontAwesomeIcon
          className="Callback__IdCard"
          icon="id-card"
          size="8x"
        />
        <FontAwesomeIcon
          className="Callback__Magnifier"
          icon="search"
          size="7x"
        />
      </div>
    );
  };

export default Callback;
