import { MuiThemeProvider } from '@material-ui/core';
import React, { useEffect } from 'react';
import * as ReactGA from 'react-ga';
import './App.css';
import getEnvInfo from './App.Env';
import customTheme from './App.MuiTheme';
import BottomRibbon from './shared/BottomRibbon';
import fontAwesomeLoad from './shared/FontAwesomeLoad';
import Routes from './shared/Routes';
import TopMenu from './shared/TopMenu';
import { version } from './version';

fontAwesomeLoad();

export interface Props {
}

const App: React.FC<Props> =
  (props, context) => {
    useEffect(() => {
      if (localStorage.getItem(`SAFE_DIAGNOSTICS`)) {
        console.warn(
          `Environment Info`,
          {
            process_env: process.env.REACT_APP_ENV,
            parsed: getEnvInfo(),
            git: version,
          },
        );
      }

      const enableGoogleAnalytics = () => {
        if (getEnvInfo().name === 'production') {
          ReactGA.initialize('UA-84988044-1');
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
      }

      enableGoogleAnalytics();
    });

    return (
      <div className="App">
        <MuiThemeProvider theme={customTheme}>
          <TopMenu />
          <Routes />
          <BottomRibbon />
        </MuiThemeProvider>
      </div>
    );
  };

export default App;
