import * as React from 'react';

interface Props {
  component: React.ComponentClass | React.FC | null | undefined;
}

export default function asyncComponent(importComponent: () => Promise<{ default: React.ComponentClass | React.FC }>) {
  class AsyncComponent extends React.Component<Props> {
    state: Props = { component: null };

    async componentDidMount(): Promise<void> {
      const { default: component } = await importComponent();
      this.setState({ component });
    }

    render = () => {
      const LoadedComponent = this.state.component;
      return LoadedComponent ?
        <LoadedComponent {...this.props} /> :
        <div className="centered">Loading...</div>;
    }
  }

  return AsyncComponent;
}