import Button from '@material-ui/core/Button';
import React from 'react';
import { Auth, IUser } from '../auth/auth';
import './UserLoggedIn.css';

interface Props {
  user: IUser;
};

const auth = new Auth();

const UserLoggedIn: React.FC<Props> =
  (props, context) => {
    const logOut = () => auth.logout();

    return (
      <span className="UserLoggedIn">
        <img className="UserLoggedIn__Avatar"
          title={props.user.fullName}
          src={props.user.pictureUrl}
          height="50"
          width="50"
          alt={`User avatar: ${props.user.fullName}`} />

        <Button
          className="UserLoggedIn__LogoutButton"
          onClick={() => logOut()}
          color="secondary"
          variant="outlined">Log Out</Button>
      </span>
    );
  };

export default UserLoggedIn;
