import Button from '@material-ui/core/Button';
import React from 'react';
import { Auth } from '../auth/auth';

export interface Props {
}

const auth = new Auth();

const UserAnonymous: React.FC<Props> =
  (props, context) => {
    const logIn = () => auth.login(window.location.pathname);

    return (<Button onClick={() => logIn()} color="secondary" variant="outlined">Log In</Button>);
  };

export default UserAnonymous;
