import { createMuiTheme } from '@material-ui/core';

const softGray = `#555555`;
const aquamarine = `#33c3f0`;
const white = `white`;

const customTheme = createMuiTheme({
  palette: {
    primary: {
      contrastText: white,
      main: aquamarine,
    },
    secondary: {
      main: softGray,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export default customTheme;
