import { library } from '@fortawesome/fontawesome-svg-core';
import { faAws, faBitcoin, faCss3Alt, faFacebook, faFontAwesomeAlt, faGithub, faHtml5, faInstagram, faLinkedin, faPaypal, faReact, faSkype, faStackExchange, faTelegram, faTwitter, faVk, faWordpress } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { faIdCard, faMobileAlt, faSearch } from '@fortawesome/free-solid-svg-icons';

export default function fontAwesomeLoad() {
  library.add(
    faEnvelopeOpen,

    faMobileAlt,
    faIdCard,
    faSearch,

    faSkype,
    faTelegram,
    faGithub,
    faWordpress,
    faStackExchange,
    faTwitter,
    faLinkedin,
    faFacebook,
    faVk,
    faInstagram,
    faBitcoin,
    faPaypal,
    faBitcoin,

    faAws,
    faReact,
    faFontAwesomeAlt,
    faHtml5,
    faCss3Alt,
  );
}
