import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './BottomRibbon.css';

export interface Props {
}

const BottomRibbon: React.FC<Props> =
  (props, context) => {
    return (
      <div className="BottomRibbon">
        <div className="BottomRibbon__Line">Designed, built, and maintained by Igor Soloydenko</div>
        <div className="BottomRibbon__Line">© 2017 — {(new Date()).getFullYear()}</div>
        <div className="BottomRibbon__Line">
          <span className="BottomRibbon__TechPiece">Powered by</span>
          <span className="BottomRibbon__TechPiece" title="React">
            <FontAwesomeIcon icon={[`fab`, `react`]} />
          </span>
          <span className="BottomRibbon__TechPiece" title="Amazon Web Services">
            <FontAwesomeIcon icon={[`fab`, `aws`]} />
          </span>
          <span className="BottomRibbon__TechPiece" title="Font Awesome">
            <FontAwesomeIcon icon={[`fab`, `font-awesome-alt`]} />
          </span>
          <span className="BottomRibbon__TechPiece" title="HTML 5">
            <FontAwesomeIcon icon={[`fab`, `html5`]} />
          </span>
          <span className="BottomRibbon__TechPiece" title="CSS 3">
            <FontAwesomeIcon icon={[`fab`, `css3-alt`]} />
          </span>
          <span className="BottomRibbon__TechPiece" title="GitHub">
            <FontAwesomeIcon icon={[`fab`, `github`]} />
          </span>
        </div>
      </div>
    );
  };

export default BottomRibbon;

