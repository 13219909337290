export default function getEnvInfo() {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return {
        baseUrl: 'https://soloydenko.com',
        name: `production`,
      };
    case 'preprod':
      return {
        baseUrl: 'https://preprod.soloydenko.com',
        name: `preprod`,
      };
    case 'deploy-preview':
      return {
        baseUrl: process.env.PUBLIC_URL,
        name: `deploy-preview`,
      };
    case 'default-build':
      return {
        baseUrl: process.env.PUBLIC_URL,
        name: `default-build`,
      };
    default:
      return {
        baseUrl: 'http://localhost:3000',
        name: `default`,
      };
  }
}
