import { Grid } from '@material-ui/core';
import React from 'react';
import { Auth } from '../auth/auth';
import './TopMenu.css';
import TopMenuItemWithRouter from './TopMenuItem';
import UserAnonymous from './UserAnonymous';
import UserLoggedIn from './UserLoggedIn';

const auth = new Auth();

export interface Props {
}

const TopMenu: React.FC<Props> =
  (props, context) => {
    const renderUser = () => {
      const user = auth.getUser();
      return user != null ? <UserLoggedIn user={user} /> : null;
    };

    return (
      <div className="TopMenu">
        <Grid
          container={true}
          spacing={16}
          justify="center"
        >

          <Grid
            item={true}
            sm={3}
            xs={12}
            className="TopMenu__ItemWrapper"
          >
            <TopMenuItemWithRouter linkTo="/about" label="About" />
          </Grid>
          <Grid
            item={true}
            sm={3}
            xs={12}
            className="TopMenu__ItemWrapper"
          >
            <TopMenuItemWithRouter linkTo="/manifesto" label="Manifesto" />
          </Grid>
          <Grid
            item={true}
            sm={3}
            xs={12}
            className="TopMenu__ItemWrapper"
          >
            <TopMenuItemWithRouter linkTo="/resume" label="Résumé" />
          </Grid>
          <Grid
            item={true}
            sm={3}
            xs={12}
            className="TopMenu__ItemWrapper"
          >
            {auth.isAuthenticated() ? renderUser() : <UserAnonymous />}
          </Grid>

        </Grid>
      </div>
    );
  };

export default TopMenu;
