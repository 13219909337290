import * as auth0 from 'auth0-js';
import decode from 'jwt-decode';
import getEnvInfo from '../App.Env';

export const ACCESS_TOKEN = `access_token`;
export const EXPIRES_AT = `expires_at`;
export const ID_TOKEN = `id_token`;

export const ROOT_PATH = `/`;
export const NAVIGATE_TO = `navigateTo`;

export interface IAuthenticationResult {
  accessToken: string;
  idToken: string;
  expiresIn: number;
};

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  pictureUrl: string;
  gender: string;
  locale: string;
};

const toLocation = (url: string): Location => {
  return url as any as Location;
}

export class Auth {
  private auth0 = new auth0.WebAuth({
    domain: `soloydenko.auth0.com`,
    clientID: `qP7s8g6lTQaZs4fxkm4MMSst5oPsIk6e`,
    audience: `https://soloydenko.auth0.com/userinfo`,
    responseType: `token id_token`,
    scope: `openid profile`,
  });

  public handleAuthentication(): void {
    this.auth0.parseHash(
      (caught: null | auth0.Auth0ParseHashError, authenticationResult: auth0.Auth0DecodedHash | null) => {
      if (caught) {
        if (getEnvInfo().name !== 'production') {
          // debugger;
        } else {
          window.location = toLocation(ROOT_PATH);
          console.error(caught);
        }
      } else if (authenticationResult && authenticationResult.accessToken && authenticationResult.idToken) {

        console.warn({ authenticationResult });
        console.warn(`window.location`, window.location);

        const queryStringParts = decodeURIComponent(window.location.search).substr(1).split(`=`);
        const navigateToParameterIndex = queryStringParts.indexOf(NAVIGATE_TO);
        const navigateTo = navigateToParameterIndex >= 0 ? queryStringParts[navigateToParameterIndex + 1] : ROOT_PATH;

        this.setSession(authenticationResult);
        window.location = toLocation(navigateTo);
      } else {
        window.location = toLocation(ROOT_PATH);
        console.error(`Can not handle auth callback!`, { authenticationResult });
      }
    });
  }

  private setSession(authResult: auth0.Auth0DecodedHash): void {
    if (authResult == null ||
      authResult.expiresIn == null ||
      authResult.accessToken == null ||
      authResult.idToken == null) {
      throw new Error(`authResult[.expiresIn|.accessToken|.idToken] was null/undefined: ${JSON.stringify(authResult)}`);
    }

    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem(ACCESS_TOKEN, authResult.accessToken);
    localStorage.setItem(EXPIRES_AT, expiresAt);
    localStorage.setItem(ID_TOKEN, authResult.idToken);
  }

  public login(path: string): void {
    const redirectUri = `${getEnvInfo().baseUrl}/callback?${NAVIGATE_TO}=${encodeURIComponent(path)}`;
    this.auth0.authorize({ redirectUri });
  }

  public logout(): void {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(EXPIRES_AT);
    localStorage.removeItem(ID_TOKEN);

    window.location = toLocation(ROOT_PATH);
  }

  public isAuthenticated(): boolean {
    const expiresAtString = localStorage.getItem('expires_at');
    return expiresAtString != null &&
      new Date().getTime() < JSON.parse(expiresAtString);
  }

  public getUser(): IUser | null {
    if (!this.isAuthenticated()) return null;

    const idToken = localStorage.getItem('id_token');
    if (!idToken) throw new Error(`id_token was null/undefined: ${JSON.stringify(idToken)}`);
    const decodedToken = decode<auth0.Auth0UserProfile>(idToken);
    return {
      id: decodedToken.sub,
      firstName: decodedToken.given_name || '',
      lastName: decodedToken.family_name || '',
      fullName: decodedToken.name,
      pictureUrl: decodedToken.picture,
      gender: decodedToken.gender || '',
      locale: decodedToken.locale || '',
    };
  }
}
