import Button from '@material-ui/core/Button';
import * as H from 'history';
import React from 'react';
import { withRouter } from 'react-router-dom';

interface Props {
  linkTo: string;
  label: string;

  history: H.History;
  location: H.Location;
  match: any;
};

const TopMenuItem: React.FC<Props> =
  (props, context) => {
    const activeClassName = () =>
      props.linkTo.toLowerCase() === (window.location.pathname || '').toLowerCase() ?
        `TopMenu__Item--Current` :
        ``;

    const redirect = () => props.history.push(props.linkTo);

    return (
      <Button
        className={`TopMenu__Item ${activeClassName()}`}
        color="primary"
        variant="text"
        onClick={() => redirect()}
      >{props.label}</Button>
    );
  };

export default withRouter<Props>(TopMenuItem);

