import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ACCESS_TOKEN, Auth, ID_TOKEN } from '../auth/auth';
import Callback from '../auth/Callback';
import asyncComponent from './AsyncComponent';

const auth = new Auth();

const handleAuthentication = (nextState: RouteComponentProps<any>) => {
  if (new RegExp(`${ACCESS_TOKEN}|${ID_TOKEN}|error`).test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
};

const AsyncAbout = asyncComponent(() => import('../pages/About'));
const AsyncManifesto = asyncComponent(() => import('../pages/Manifesto'));
const AsyncResume = asyncComponent(() => import('../pages/Resume'));
const AsyncPageNotFound = asyncComponent(() => import('../pages/PageNotFound'));

export default (): JSX.Element =>
  <Switch>
    <Route
      path='/'
      exact={true}
      render={(props: RouteComponentProps<any>) => <Redirect to="/about" />}
    />

    <Route
      path='/callback'
      exact={true}
      render={(props: RouteComponentProps<any>) => {
        handleAuthentication(props);
        return <Callback {...props} />
      }}
    />

    <Route
      path='/about'
      exact={true}
      component={AsyncAbout}
    />
    <Route
      path='/manifesto'
      exact={true}
      component={AsyncManifesto}
    />
    <Route
      path='/resume'
      exact={true}
      component={AsyncResume}
    />

    <Route
      component={AsyncPageNotFound}
    />
  </Switch>
  ;